import React from 'react'
import { graphql } from 'gatsby'
import Layout from './base-with-sidebar'

export default (props) => <Layout {...props} />

export const query = graphql`
  query ($categoryRegex: String!) {
    allMarkdownRemark(
      filter: {
        fields: {
          category: {
            regex: $categoryRegex
          }
        }
      }
    ) {
      edges {
        node {
          ...MarkdownFrontmatter
        }
      }
    }
  }
`
